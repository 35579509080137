import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path1/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/components/font-loader/FontLoader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path1/src/components/storyblok-bridge/StoryblokBridge.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path1/src/styles/globals.scss");
