'use client';

import { loadStoryblokBridge, StoryblokBridgeConfigV2 } from '@storyblok/js';

interface StoryblokBridgeLoaderProps {
  options: StoryblokBridgeConfigV2;
  [key: string]: unknown;
}

const setBridge = async (options: StoryblokBridgeConfigV2) => {
  await loadStoryblokBridge();
  const sbBridge = new window.StoryblokBridge(options);

  sbBridge.on(['published', 'change'], () => {
    window.location.reload();
  });
};

const StoryblokBridge = ({ options }: StoryblokBridgeLoaderProps) => {
  setBridge(options);
  return null;
};

export default StoryblokBridge;
