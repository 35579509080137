'use client';

import { useEffect, useState } from "react";

/**
 * Manually loading the fonts after the page has finished loading to improve LCP score
 * */
export default function FontLoader() {
  const [shouldLoad, setShouldLoad] = useState(false);

  useEffect(() => {
    setShouldLoad(true);
  }, [])

  useEffect(() => {
    if (shouldLoad) {
      const fontWeight = '100 1000';

      const montserrat = new FontFace('Montserrat', "url(/fonts/Montserrat-VF.woff2)", {weight: fontWeight});
      const montserratItalic = new FontFace('Montserrat', "url(/fonts/Montserrat-Italic-VF.woff2)", {style: 'italic', weight: fontWeight});
      const inter = new FontFace('Inter', "url(/fonts/Inter-VF.woff2)", {weight: fontWeight});
      const interItalic = new FontFace('Inter', "url(/fonts/Inter-Italic-VF.woff2)", {style: 'italic', weight: fontWeight});

      [montserrat, montserratItalic, inter, interItalic].forEach((font) => {
        document.fonts.add(font);
      })
    }
  }, [shouldLoad]);

  return null;
}